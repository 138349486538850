import {
  Button,
  Flex,
  Heading,
  HStack,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { getAnnouncements } from './api/api';
import { ROLES } from './constants';
import CreateAnnouncementModal from './CreateAnnouncementModal';
import { useAuth } from './hooks/useProvideAuth';
import { Shell } from './Shell';

export default function Announcements() {
  const [announcements, setAnnouncements] = useState([
    { title: '', content: '', date: new Date() },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const auth = useAuth();

  useEffect(() => {
    getAnnouncements()
      .then(res => setAnnouncements(res.data.announcements))
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Shell>
      <Stack
        spacing={{
          base: '8',
          lg: '6',
        }}
      >
        <Stack
          spacing="4"
          direction={{
            base: 'column',
            lg: 'row',
          }}
          justify="space-between"
          align={{
            base: 'start',
            lg: 'center',
          }}
        >
          <Stack spacing="1">
            <Heading
              size={useBreakpointValue({
                base: 'xs',
                lg: 'sm',
              })}
              fontWeight="medium"
            >
              Announcements
            </Heading>
            <Text color="muted">Your announcements</Text>
          </Stack>
          {auth && auth.roles.includes(ROLES.ADMIN) && (
            <HStack spacing="3">
              <Button
                variant="primary"
                leftIcon={<FiEdit fontSize="1.25rem" />}
                onClick={() => onOpen()}
              >
                Create
              </Button>
            </HStack>
          )}
        </Stack>
        {isLoading && (
          <Flex
            bg={'white'}
            p={8}
            justifyContent={'space-between'}
            alignItems={'center'}
            shadow={'md'}
            borderRadius={'md'}
          >
            <Stack w={'full'}>
              <Skeleton h="2rem" w="20rem" />
              <Skeleton h="1.5rem" w={'5rem'} />
              <Skeleton h="20px" w={'full'} />
              <Skeleton h="20px" w={'full'} />
              <Skeleton h="20px" w={'full'} />
            </Stack>
          </Flex>
        )}
        {!isLoading && announcements.length < 1 && (
          <Flex w={'full'} justifyContent={'center'}>
            <Text fontSize={'sm'}>No announcements yet.</Text>
          </Flex>
        )}
        {!isLoading &&
          announcements.map(value => {
            return (
              <Flex
                key={value}
                bg={'white'}
                p={8}
                justifyContent={'space-between'}
                alignItems={'center'}
                shadow={'md'}
                borderRadius={'md'}
              >
                <Stack>
                  <Text fontSize={'2xl'} fontWeight={'semibold'}>
                    {value.title}
                  </Text>
                  <Text fontWeight={'light'}>
                    {new Date(value.createdAt).toLocaleDateString()}
                  </Text>
                  <Text mt={8}>{value.content}</Text>
                </Stack>
              </Flex>
            );
          })}
      </Stack>
      <CreateAnnouncementModal
        onClose={onClose}
        isOpen={isOpen}
        afterSave={announcement => {
          setAnnouncements([...announcements, announcement]);
          setAnnouncements(prevAnnouncements => {
            const updatedAnnouncements = prevAnnouncements.sort(
              (a, b) => b.date - a.date
            );
            return [...updatedAnnouncements];
          });
        }}
      />
    </Shell>
  );
}
