import {
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import {
  getCustomerOrdersInProgress,
  getCustomerShipments,
  getCustomerShipmentsXDaysAgo,
  getUnreadAnnouncements,
} from './api/api';
import { Card } from './Card';
import { SHIPMENTS_TABLE_HEADERS } from './constants';
import { DataTable } from './DataTable';
import { Shell } from './Shell';
import { TableWithSearch } from './TableWithSearch';

export default function Home() {
  const [searchText, setSearchText] = useState('');
  const [countUnreadAnnouncements, setCountUnreadAnnouncements] = useState(0);
  const [countShipmentsSevenDaysAgo, setCountShipmentsSevenDaysAgo] =
    useState(0);
  const [countOrdersInProgress, setCountOrdersInProgress] = useState({
    processing: 0,
    shipped: 0,
  });
  const [shipments, setShipments] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCustomerShipments()
      .then(res => setShipments(res.data.shipments))
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getUnreadAnnouncements()
      .then(res => setCountUnreadAnnouncements(res.data.count))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    getCustomerShipmentsXDaysAgo(7)
      .then(res => setCountShipmentsSevenDaysAgo(res.data.count))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    getCustomerOrdersInProgress()
      .then(res => setCountOrdersInProgress(res.data))
      .catch(error => console.error(error));
  }, []);

  return (
    <Shell>
      <Stack
        spacing={{
          base: '8',
          lg: '6',
        }}
      >
        <Stack
          spacing="4"
          direction={{
            base: 'column',
            lg: 'row',
          }}
          justify="space-between"
          align={{
            base: 'start',
            lg: 'center',
          }}
        >
          <Stack spacing="1">
            <Heading
              size={useBreakpointValue({
                base: 'xs',
                lg: 'sm',
              })}
              fontWeight="medium"
            >
              Home
            </Heading>
            <Text color="muted">Your dashboard at a glance</Text>
          </Stack>
        </Stack>
        <Stack
          spacing={{
            base: '5',
            lg: '6',
          }}
        >
          <SimpleGrid
            columns={{
              base: 1,
              md: 3,
            }}
            gap="6"
          >
            <Card>
              <Flex
                flexGrow={1}
                h={'100%'}
                justifyContent={'start'}
                flexDir={'column'}
              >
                <Flex flexGrow={1} h={'100%'} justifyContent={'start'} p={4}>
                  <VStack align={'left'} w={'full'}>
                    <Text fontWeight={'semibold'}>Orders</Text>
                    <Flex justifyContent={'space-between'}>
                      <Text fontWeight={'light'} fontSize={'sm'}>
                        Processing:
                      </Text>
                      <Text fontWeight={'bold'} fontSize={'sm'}>
                        {countOrdersInProgress.processing}
                      </Text>
                    </Flex>
                    <Flex justifyContent={'space-between'}>
                      <Text fontWeight={'light'} fontSize={'sm'}>
                        Shipped:
                      </Text>
                      <Text fontWeight={'bold'} fontSize={'sm'}>
                        {countOrdersInProgress.shipped}
                      </Text>
                    </Flex>
                  </VStack>
                </Flex>
                <Flex justifyContent={'end'}>
                  <Button
                    onClick={() => navigate('/orders')}
                    variant={'link'}
                    p={4}
                    colorScheme={'blue'}
                    rightIcon={<FiArrowRight />}
                  >
                    Go to Orders
                  </Button>
                </Flex>
              </Flex>
            </Card>
            <Card>
              <Flex
                flexGrow={1}
                h={'100%'}
                justifyContent={'start'}
                flexDir={'column'}
              >
                <Flex flexGrow={1} h={'100%'} justifyContent={'start'} p={4}>
                  <VStack align={'left'} w={'full'}>
                    <Text fontWeight={'semibold'}>Shipments</Text>
                    <Flex justifyContent={'space-between'}>
                      <Text fontWeight={'light'} fontSize={'sm'}>
                        Shipments in past 7 days:
                      </Text>
                      <Text fontWeight={'bold'} fontSize={'sm'}>
                        {countShipmentsSevenDaysAgo}
                      </Text>
                    </Flex>
                  </VStack>
                </Flex>
                <Flex justifyContent={'end'}>
                  <Button
                    onClick={() => navigate('/shipments')}
                    variant={'link'}
                    p={4}
                    colorScheme={'blue'}
                    rightIcon={<FiArrowRight />}
                  >
                    Go to Shipments
                  </Button>
                </Flex>
              </Flex>
            </Card>
            <Card>
              <Flex
                flexGrow={1}
                h={'100%'}
                justifyContent={'start'}
                flexDir={'column'}
              >
                <Flex flexGrow={1} h={'100%'} justifyContent={'start'} p={4}>
                  <VStack align={'left'} w={'full'}>
                    <Text fontWeight={'semibold'}>Announcements</Text>
                    <Flex justifyContent={'space-between'}>
                      <Text fontWeight={'light'} fontSize={'sm'}>
                        Unread Announcements:
                      </Text>
                      <Text fontWeight={'bold'} fontSize={'sm'}>
                        {countUnreadAnnouncements}
                      </Text>
                    </Flex>
                  </VStack>
                </Flex>
                <Flex justifyContent={'end'}>
                  <Button
                    onClick={() => navigate('/announcements')}
                    variant={'link'}
                    p={4}
                    colorScheme={'blue'}
                    rightIcon={<FiArrowRight />}
                  >
                    Go to Announcements
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </SimpleGrid>
        </Stack>
        <TableWithSearch
          title="Latest Shipments"
          onSearch={text => setSearchText(text)}
          isLoading={isLoading}
          totalResults={1}
        >
          <DataTable
            isLoading={isLoading}
            headers={SHIPMENTS_TABLE_HEADERS}
            data={shipments?.filter(
              value =>
                JSON.stringify(value)
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) || searchText === ''
            )}
          />
        </TableWithSearch>
      </Stack>
    </Shell>
  );
}
