import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { msalConfig } from './authConfig';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <StrictMode>
    <MsalProvider instance={msalInstance}>
      <ColorModeScript />
      <App />
    </MsalProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
