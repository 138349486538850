import {
  Button,
  Heading,
  HStack,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaFileExport } from 'react-icons/fa';
import {
  getCompanies,
  getCustomerOrders,
  getCustomerOrdersByIds,
} from './api/api';
import { ORDERS_TABLE_HEADERS, ROLES } from './constants';
import { DataTable } from './DataTable';
import { useAuth } from './hooks/useProvideAuth';
import { Shell } from './Shell';
import { TableWithSearch } from './TableWithSearch';
import { exportToCSV } from './utils/csvHelper';

export default function Orders() {
  const [searchText, setSearchText] = useState('');
  const [filterSearch, setFilterSearch] = useState('');
  const [filters, setFilters] = useState({ dateRange: {}, companyIds: [] });
  const [companies, setCompanies] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [openOrdersOnly, setOpenOrdersOnly] = useState(false);
  const [shippingOrdersOnly, setShippingOrdersOnly] = useState(false);
  const [selections, setSelections] = useState({
    allSelected: false,
    selections: [],
  });
  const [tablePage, setTablePage] = useState(1);
  const [tableTotalPages, setTableTotalPages] = useState(1);
  const [tableTotalRecords, setTableTotalRecords] = useState(1);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    findOrders();
  }, [tablePage]);

  useEffect(() => {
    setTablePage(1);
    findOrders();
  }, [filters, openOrdersOnly, shippingOrdersOnly]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMounted(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (mounted) {
        setTablePage(1);
        findOrders();
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [searchText]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      getCompanies(1, 10, filterSearch)
        .then(res => {
          const currentCompanies = res.data.companies;
          const filterOptionsCompanies = currentCompanies.map(company => ({
            label: company.customerCompany,
            value: company.customerNumber,
          }));
          setCompanies(filterOptionsCompanies);
        })
        .catch(error => console.error(error));
    }, 1000);
    return () => clearTimeout(timeout);
  }, [filterSearch]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      getCompanies(1, 10, filterSearch)
        .then(res => {
          const currentCompanies = res.data.companies;
          const filterOptionsCompanies = currentCompanies.map(company => ({
            label: company.customerCompany,
            value: company.customerNumber,
          }));
          setCompanies(filterOptionsCompanies);
        })
        .catch(error => {
          console.error(error);
          setCompanies([]);
        });
    }, 1000);
    return () => clearTimeout(timeout);
  }, [filterSearch]);

  const findOrders = () => {
    setIsLoading(true);
    const companyIds = filters.companyIds.map(filter => filter.value);
    getCustomerOrders(
      tablePage,
      10,
      searchText,
      companyIds,
      filters.dateRange,
      openOrdersOnly,
      shippingOrdersOnly
    )
      .then(res => {
        setOrders(res.data.orders);
        setTablePage(res.data.currentPage);
        setTableTotalPages(res.data.totalPages);
        setTableTotalRecords(res.data.totalRecords);
      })
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
  };

  const downloadSelectionsToCSV = async () => {
    try {
      setExportLoading(true);

      const companyIds = filters.companyIds.map(filter => filter.value);
      const rows = await getCustomerOrdersByIds(
        selections.selections,
        selections.allSelected,
        companyIds,
        filters.dateRange,
        openOrdersOnly,
        shippingOrdersOnly
      );
      const ordersToExport = rows.data.orders;
      if (!ordersToExport.length) {
        setExportLoading(false);
        return;
      }
      const allowed = Object.keys(ORDERS_TABLE_HEADERS);
      const ordersValues = ordersToExport.map(value => {
        const filtered = Object.keys(value)
          .filter(key => allowed.includes(key))
          .reduce((obj, key) => {
            obj[key] = value[key];
            return obj;
          }, {});
        const orderedVals = [];
        for (const val of allowed) {
          if (!filtered[val]) {
            orderedVals.push('');
          } else if (ORDERS_TABLE_HEADERS[val].type === 'date') {
            orderedVals.push(new Date(filtered[val]).toLocaleDateString());
          } else {
            orderedVals.push(filtered[val]);
          }
        }

        return orderedVals;
      });
      const ordersCSVRows = [allowed, ...ordersValues];
      exportToCSV(`orders-${new Date().toISOString()}.csv`, ordersCSVRows);
    } catch (error) {
      console.error(error);
      alert('Something went wrong when exporting the data:', error.message);
    } finally {
      setExportLoading(false);
    }
  };

  return (
    <Shell>
      <Stack
        spacing={{
          base: '8',
          lg: '6',
        }}
      >
        <Stack
          spacing="4"
          direction={{
            base: 'column',
            lg: 'row',
          }}
          justify="space-between"
          align={{
            base: 'start',
            lg: 'center',
          }}
        >
          <Stack spacing="1">
            <Heading
              size={useBreakpointValue({
                base: 'xs',
                lg: 'sm',
              })}
              fontWeight="medium"
            >
              Orders
            </Heading>
            <Text color="muted">Your orders</Text>
          </Stack>
          <Tooltip
            label={
              !selections.selections.length && !selections.allSelected
                ? 'Select rows to export'
                : ''
            }
          >
            <HStack spacing="3">
              <Button
                variant="primary"
                isLoading={exportLoading}
                isDisabled={
                  !selections.selections.length && !selections.allSelected
                }
                onClick={() => downloadSelectionsToCSV()}
                leftIcon={<FaFileExport fontSize="1.25rem" />}
              >
                Export CSV
              </Button>
            </HStack>
          </Tooltip>
        </Stack>
        <TableWithSearch
          title="Orders"
          onSearch={text => setSearchText(text)}
          page={tablePage}
          isLoading={isLoading}
          setPage={setTablePage}
          totalResults={tableTotalRecords}
          totalPages={tableTotalPages}
          filterOptions={companies}
          onFilterChange={setFilters}
          onFilterSearchChange={setFilterSearch}
          showFilter={auth.user && auth.user.roles?.includes(ROLES.ADMIN)}
          openOrdersOnly={openOrdersOnly}
          onOpenOrdersOnly={value => {
            if (value) {
              setShippingOrdersOnly(false);
            }
            setOpenOrdersOnly(value);
          }}
          shippingOrdersOnly={shippingOrdersOnly}
          onShippingOrdersOnly={value => {
            if (value) {
              setOpenOrdersOnly(false);
            }
            setShippingOrdersOnly(value);
          }}
        >
          <DataTable
            headers={ORDERS_TABLE_HEADERS}
            isLoading={isLoading}
            data={orders}
            onSelectionChange={updatedSelections =>
              setSelections(updatedSelections)
            }
          />
        </TableWithSearch>
      </Stack>
    </Shell>
  );
}
