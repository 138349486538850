import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  getUser,
  loginLocal,
  loginMicrosoft,
  signOutUser,
  signupLocal,
} from '../api/api';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]);

  const signUpWithLocal = async userInfo => {
    await signupLocal(userInfo);
  };

  const loginWithMicrosoft = async (msalInstance, loginRequest) => {
    await loginMicrosoft(msalInstance, loginRequest);
  };

  const loginWithLocal = async userInfo => {
    await loginLocal(userInfo);
  };

  const signOut = async () => {
    await signOutUser();
    window.location.reload();
  };

  useEffect(() => {
    setLoading(true);

    getUser()
      .then(response => {
        const authUser = response.data.user;
        setUser(authUser);
        setLoading(false);
        setRoles(authUser.roles);
      })
      .catch(() => {
        setUser(null);
        setRoles(null);
        setLoading(false);
      });

    return () => {
      setLoading(false);
      setUser(null);
      setRoles(null);
    };
  }, []);

  return {
    user,
    loading,
    roles,
    loginWithMicrosoft,
    loginWithLocal,
    signUpWithLocal,
    signOut,
  };
}
