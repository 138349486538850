import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { FiCheck, FiPlus, FiSearch, FiX } from 'react-icons/fi';
import DateRangePicker from './DateRangePicker';

export const TableWithSearch = ({
  title = 'Table',
  page = 1,
  maxRows = 10,
  totalResults = 1,
  totalPages = 1,
  setPage = () => {},
  onSearch = () => {},
  isLoading = false,
  filterOptions = [],
  onFilterChange = () => {},
  onFilterSearchChange = () => {},
  openOrdersOnly = false,
  onOpenOrdersOnly = () => {},
  shippingOrdersOnly = false,
  onShippingOrdersOnly = () => {},
  showFilter: showCompaniesFilter = false,
  children,
}) => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });
  const [filters, setFilters] = React.useState({
    dateRange: {},
    companyIds: [],
  });
  const [filterSearch, setFilterSearch] = React.useState('');
  const [openOrders, setOpenOrders] = React.useState(false);

  React.useEffect(() => {
    onFilterChange(filters);
  }, [filters]);

  React.useEffect(() => {
    onFilterSearchChange(filterSearch);
  }, [filterSearch]);

  React.useEffect(() => {
    onOpenOrdersOnly(openOrders);
  }, [openOrders]);

  return (
    <Box
      py={{
        base: '4',
        md: '8',
      }}
      px={{
        base: '0',
        md: 0,
      }}
      w={'full'}
    >
      <Box
        bg="bg-surface"
        boxShadow={{
          base: 'none',
          md: useColorModeValue('sm', 'sm-dark'),
        }}
        borderRadius={useBreakpointValue({
          base: 'none',
          md: 'lg',
        })}
      >
        <Stack spacing="5">
          <Box
            px={{
              base: '4',
              md: '6',
            }}
            pt="5"
          >
            <Stack
              direction={{
                base: 'column',
                md: 'row',
              }}
              justify="space-between"
            >
              <HStack spacing="4" align="center">
                <Text fontSize="lg" fontWeight="medium">
                  {title}
                </Text>

                <Menu>
                  <MenuButton
                    as={Button}
                    leftIcon={<FiPlus fontSize={'1.25rem'} />}
                    size={'sm'}
                  >
                    Add Filter
                  </MenuButton>
                  <MenuList minWidth="240px">
                    <MenuOptionGroup
                      title="Add Filters"
                      type="checkbox"
                      value={filters}
                      onChange={value =>
                        setFilters(prev => ({ ...prev, companyIds: value }))
                      }
                    >
                      <DateRangePicker
                        mt={4}
                        value={filters.dateRange}
                        onChange={value =>
                          setFilters(prev => ({ ...prev, dateRange: value }))
                        }
                      />
                      <Checkbox
                        mt={4}
                        mx={4}
                        isChecked={openOrdersOnly}
                        onChange={e => setOpenOrders(e.target.checked)}
                      >
                        Open orders
                      </Checkbox>
                      <Checkbox
                        mt={4}
                        mx={4}
                        isChecked={shippingOrdersOnly}
                        onChange={e => onShippingOrdersOnly(e.target.checked)}
                      >
                        Shipping orders
                      </Checkbox>
                      {showCompaniesFilter && (
                        <>
                          <Text fontSize={'sm'} px={4} my={2} mt={8}>
                            Select a Company
                          </Text>
                          <InputGroup maxW="full" px={4} my={2}>
                            <InputLeftElement pointerEvents="none" pl={6}>
                              <Icon as={FiSearch} color="muted" boxSize="5" />
                            </InputLeftElement>
                            <Input
                              placeholder="Search"
                              onChange={e => setFilterSearch(e.target.value)}
                            />
                          </InputGroup>
                          {filterOptions.map(value => (
                            <Box
                              key={value.value}
                              value={value}
                              _hover={{
                                bg: 'gray.100',
                                cursor: 'pointer',
                              }}
                              isFocusable={false}
                              onClick={e => {
                                setFilters(prev => {
                                  const companyExists = prev.companyIds.find(
                                    filter => filter.value === value.value
                                  );

                                  if (companyExists) {
                                    return prev.companyIds.filter(
                                      filter => filter.value !== value.value
                                    );
                                  }

                                  return {
                                    ...prev,
                                    companyIds: [...prev.companyIds, value],
                                  };
                                });
                              }}
                              px={4}
                              py={2}
                            >
                              <Icon
                                as={FiCheck}
                                mr={2}
                                visibility={
                                  filters.companyIds.filter(
                                    filter => filter.value === value.value
                                  ).length
                                    ? ''
                                    : 'hidden'
                                }
                              />
                              {value.label}
                            </Box>
                          ))}
                          <Text mx={'9'} mt={2} fontSize={'sm'}>
                            Search to see more...
                          </Text>
                        </>
                      )}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
                {filters.companyIds.map(filter => (
                  <Badge
                    colorScheme={'blue'}
                    key={filter.value}
                    size={'lg'}
                    alignItems={'center'}
                  >
                    <HStack spacing="2" align="center" maxW={'10rem'}>
                      <Icon
                        as={FiX}
                        boxSize="5"
                        transition={'all 0.2s ease-in-out'}
                        _hover={{ color: 'gray.400', cursor: 'pointer' }}
                        onClick={() =>
                          setFilters(prev => ({
                            ...prev,
                            companyIds: prev.companyIds.filter(
                              f => f !== filter
                            ),
                          }))
                        }
                      />
                      <Tooltip label={filter.label} openDelay={500}>
                        <Text isTruncated>{filter.label}</Text>
                      </Tooltip>
                    </HStack>
                  </Badge>
                ))}
              </HStack>
              <InputGroup maxW="xs">
                <InputLeftElement pointerEvents="none">
                  <Icon as={FiSearch} color="muted" boxSize="5" />
                </InputLeftElement>
                <Input
                  placeholder="Search"
                  onChange={e => onSearch(e.target.value)}
                />
              </InputGroup>
            </Stack>
          </Box>
          <Box overflowX="auto">{children}</Box>
          <Box
            px={{
              base: '4',
              md: '6',
            }}
            pb="5"
          >
            <HStack spacing="3" justify="space-between">
              {!isMobile && (
                <Text color="muted" fontSize="sm">
                  Showing {(page - 1) * maxRows + 1} to{' '}
                  {totalResults < (page - 1) * maxRows + maxRows
                    ? totalResults
                    : (page - 1) * maxRows + maxRows}{' '}
                  of {totalResults}
                </Text>
              )}
              {maxRows < totalResults && (
                <ButtonGroup
                  spacing="3"
                  justifyContent="space-between"
                  width={{
                    base: 'full',
                    md: 'auto',
                  }}
                  variant="secondary"
                >
                  <Button
                    isLoading={isLoading}
                    isDisabled={page <= 1}
                    onClick={() => setPage(page - 1)}
                  >
                    Previous
                  </Button>
                  <Button
                    isLoading={isLoading}
                    isDisabled={(page - 1) * maxRows + maxRows >= totalResults}
                    onClick={() => setPage(page + 1)}
                  >
                    Next
                  </Button>
                </ButtonGroup>
              )}
            </HStack>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
