import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { USER_STATUS } from './constants';
import { useAuth } from './hooks/useProvideAuth';

export default function PrivateRoute({ children, ...rest }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const auth = useAuth();

  useEffect(() => {
    setUser(auth.user);
    setLoading(auth.loading);
  }, [auth]);

  return (
    !loading &&
    (user && user.status !== USER_STATUS.INACTIVE ? (
      <Outlet />
    ) : (
      <Navigate to="/auth" />
    ))
  );
}
