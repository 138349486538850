import {
  Badge,
  Box,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { IoMdArrowDown, IoMdArrowUp } from 'react-icons/io';

export const DataTable = ({
  headers = {},
  data = [],
  editable,
  editClicked = () => {},
  onSelectionChange = () => {},
  deleteable,
  deleteClicked = () => {},
  customButton,
  isLoading,
  maxRows = 10,
  ...rest
}) => {
  const [selections, setSelections] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [sortBy, setSortBy] = useState({ name: 'date', descending: false });
  const headerKeys = Object.keys(headers);

  useEffect(() => {
    onSelectionChange({ allSelected, selections });
  }, [selections, allSelected]);

  const getSkeleton = () => {
    const skeleton = [];
    for (let i = 1; i <= maxRows; i++) {
      skeleton.push(
        <Tr key={`skeleton-${i}`}>
          {headerKeys.map(_ => (
            <Td key={_}>
              <Skeleton height="20px" />
            </Td>
          ))}
        </Tr>
      );
    }
    return skeleton;
  };
  return (
    <Table {...rest}>
      <Thead>
        <Tr>
          {Object.values(headers).map((value, index) => (
            <Th key={`header-${value.label}`}>
              <HStack spacing="3">
                {index === 0 && !customButton && (
                  <Checkbox
                    isChecked={allSelected}
                    onChange={e => {
                      setSelections([]);
                      setAllSelected(e.target.checked);
                    }}
                  />
                )}
                {index === 0 && customButton && <Box w={10} />}
                <HStack
                  spacing="1"
                  onClick={() =>
                    setSortBy(prev => {
                      if (value.label === prev.name) {
                        return { ...prev, descending: !prev.descending };
                      }
                      return {
                        name: value.label,
                        descending: true,
                      };
                    })
                  }
                >
                  <Text>{value.label}</Text>
                  {sortBy.name === value.label && (
                    <Icon
                      as={sortBy.descending ? IoMdArrowDown : IoMdArrowUp}
                      color="muted"
                      boxSize="4"
                    />
                  )}
                </HStack>
              </HStack>
            </Th>
          ))}
          {(editable || deleteable) && <Th></Th>}
        </Tr>
      </Thead>
      <Tbody>
        {isLoading && getSkeleton()}
        {!isLoading &&
          data.map((datum, index) => (
            <Tr key={`datum-${index}`}>
              {headerKeys.map((value, index) => (
                <Td key={`${JSON.stringify(value)}-${index}`}>
                  <HStack spacing="3">
                    {index === 0 && !customButton && (
                      <Checkbox
                        isChecked={
                          selections.indexOf(datum._id) > -1 || allSelected
                        }
                        onChange={e =>
                          setSelections(prev => {
                            const selectionIndex = selections.indexOf(
                              datum._id
                            );
                            if (selectionIndex > -1) {
                              prev.splice(selectionIndex, 1);
                            } else {
                              prev.push(datum._id);
                            }
                            return [...prev];
                          })
                        }
                      />
                    )}
                    {index === 0 && customButton && customButton(datum._id)}
                    <Box>
                      {headers[value].type !== 'array' &&
                        headers[value].type !== 'enum' && (
                          <Text color="muted" isTruncated maxW={'24ch'}>
                            {headers[value].type === 'date'
                              ? datum[value]
                                ? new Date(datum[value]).toLocaleDateString()
                                : 'N/A'
                              : datum[value]}
                          </Text>
                        )}
                      {headers[value].type === 'array' &&
                        datum[value].map((item, index) => (
                          <Badge
                            mx={1}
                            colorScheme={'blue'}
                            key={`${JSON.stringify(item)}-${index}`}
                          >
                            {item}
                          </Badge>
                        ))}
                      {headers[value].type === 'enum' && (
                        <Badge
                          colorScheme={
                            headers[value].options[datum[value]].color
                          }
                        >
                          {headers[value].options[datum[value]].name}
                        </Badge>
                      )}
                    </Box>
                  </HStack>
                </Td>
              ))}

              {(editable || deleteable) && (
                <Td>
                  <HStack spacing="1">
                    {deleteable && (
                      <IconButton
                        icon={<FiTrash2 fontSize="1.25rem" />}
                        variant="ghost"
                        aria-label="Delete member"
                        onClick={() => deleteClicked(datum)}
                      />
                    )}
                    {editable && (
                      <IconButton
                        icon={<FiEdit2 fontSize="1.25rem" />}
                        variant="ghost"
                        aria-label="Edit member"
                        onClick={() => editClicked(datum)}
                      />
                    )}
                  </HStack>
                </Td>
              )}
            </Tr>
          ))}
      </Tbody>
    </Table>
  );
};
