import { theme } from '@chakra-ui/pro-theme';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import '@fontsource/inter/variable.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Announcements from './Announcements';
import { Auth } from './Auth';
import { Blank } from './Blank';
import Companies from './Companies';
import Home from './Home';
import { ProvideAuth } from './hooks/useProvideAuth';
import Orders from './Orders';
import PrivateRoute from './PrivateRoute';
import Shipments from './Shipments';
import { Signup } from './Signup';
import Users from './Users';

export default function App() {
  const myTheme = extendTheme(
    {
      colors: { ...theme.colors, brand: theme.colors.blue },
    },
    theme
  );
  return (
    <ChakraProvider theme={myTheme}>
      <ProvideAuth>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/" element={<Home />} />
            </Route>
            <Route path="/orders" element={<PrivateRoute />}>
              <Route path="/orders" element={<Orders />} />
            </Route>
            <Route path="/shipments" element={<PrivateRoute />}>
              <Route path="/shipments" element={<Shipments />} />
            </Route>
            <Route path="/announcements" element={<PrivateRoute />}>
              <Route path="/announcements" element={<Announcements />} />
            </Route>
            <Route path="/companies" element={<PrivateRoute />}>
              <Route path="/companies" element={<Companies />} />
            </Route>
            <Route path="/users" element={<PrivateRoute />}>
              <Route path="/users" element={<Users />} />
            </Route>
            <Route path="/auth" element={<Auth />} />
            <Route path="/_blank" element={<Blank />} />
            <Route path="/signup" element={<Signup />} />
          </Routes>
        </BrowserRouter>
      </ProvideAuth>
    </ChakraProvider>
  );
}
