import { chakra, useColorMode } from '@chakra-ui/react';

export const Logo = props => {
  // const color = useColorMode('#000', '#FFF');
  const { colorMode } = useColorMode();
  return (
    <chakra.svg
      color={'accent'}
      height="8"
      width="auto"
      viewBox="0 0 196 44"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g fill={colorMode === 'light' ? '#1A202C' : '#FFF'}>
          <path d="M20.907.77L16.78 14.476 12.968.77H0v22.492h8.095V5.692l5.312 17.57h6.811l5.376-17.57v17.57h8.095V.77zM50.076 18.513c3.349 0 5.161-1.892 5.161-5.397 0-3.504-1.812-5.424-5.161-5.424-3.349 0-5.161 1.92-5.161 5.424 0 3.505 1.812 5.397 5.161 5.397m0-15.968c8.986 0 13.561 3.617 13.561 10.571 0 6.953-4.575 10.57-13.561 10.57-8.984 0-13.561-3.617-13.561-10.57 0-6.954 4.577-10.57 13.561-10.57M66.9 3.075h9.236l7.784 11.571V3.075h7.562v20.082H82.72L74.265 11.39v11.767H66.9zM107.999 18.513c3.349 0 5.16-1.892 5.16-5.397 0-3.504-1.811-5.424-5.16-5.424-3.35 0-5.161 1.92-5.161 5.424 0 3.505 1.812 5.397 5.16 5.397m0-15.968c8.986 0 13.562 3.617 13.562 10.571 0 6.953-4.576 10.57-13.561 10.57-8.984 0-13.56-3.617-13.56-10.57 0-6.954 4.576-10.57 13.56-10.57M124.891 3.075h20.119v4.924h-12.306v2.724h10.045v4.951h-10.045v7.483h-7.813zM148.329 3.075h7.812V17.65h10.63v5.507h-18.442zM182.452 18.513c3.349 0 5.16-1.892 5.16-5.397 0-3.504-1.811-5.424-5.16-5.424-3.35 0-5.161 1.92-5.161 5.424 0 3.505 1.812 5.397 5.16 5.397m0-15.968c8.985 0 13.56 3.617 13.56 10.571 0 6.953-4.575 10.57-13.56 10.57-8.983 0-13.56-3.617-13.56-10.57 0-6.954 4.577-10.57 13.56-10.57"></path>
        </g>
        <g fill={colorMode === 'light' ? '#4A5568' : '#999B9E'}>
          <path d="M.348 43.603h1.475V29.421H.348zM7.262 29.42h1.773l9.942 12.118h.041V29.42h1.475v14.182h-1.715L8.775 31.487h-.039v12.115H7.262zM29.993 30.771h-5.578v-1.35h12.631v1.35h-5.578v12.833h-1.475zM41.607 29.42h11.318v1.35h-9.843v4.807h9.484v1.352h-9.484v5.323h10.003v1.35H41.607zM59.377 36.015h6.237c1.613 0 3.068-.596 3.068-2.76 0-2.086-1.574-2.484-2.928-2.484h-6.377v5.244zm-1.475-6.594h7.75c2.413 0 4.624.853 4.624 3.834 0 1.51-.935 3.018-2.79 3.436v.039c2.013.318 2.432 1.61 2.531 3.933.04.893.16 2.622.499 2.94h-1.594c-.259-.299-.358-1.351-.378-2.9-.04-2.503-.997-3.336-3.448-3.336h-5.719v6.236h-1.475V29.421zM74.677 29.42h1.773l9.942 12.118h.041V29.42h1.475v14.182h-1.713L76.191 31.487h-.039v12.115h-1.475zM98.644 30.89l-3.168 7.053h6.297l-3.129-7.052zm3.727 8.403h-7.493l-1.933 4.31h-1.593l6.456-14.183h1.713l6.457 14.183h-1.715l-1.892-4.31zM112.056 30.771h-5.578v-1.35h12.631v1.35h-5.578v12.833h-1.475zM122.793 43.603h1.475V29.421h-1.475zM136.481 42.61c3.127 0 6.137-1.828 6.137-6.099 0-4.27-3.01-6.098-6.137-6.098-3.129 0-6.137 1.828-6.137 6.098 0 4.271 3.008 6.099 6.137 6.099m0-13.547c4.045 0 7.731 2.443 7.731 7.448 0 5.006-3.686 7.449-7.73 7.449-4.046 0-7.732-2.443-7.732-7.449 0-5.005 3.686-7.448 7.731-7.448M148.533 29.42h1.773l9.942 12.118h.041V29.42h1.475v14.182h-1.715l-10.002-12.115h-.039v12.115h-1.475zM172.898 30.89l-3.168 7.053h6.297l-3.129-7.052zm3.727 8.403h-7.493l-1.933 4.31h-1.594l6.457-14.183h1.713l6.456 14.183h-1.714l-1.892-4.31zM184.074 29.42h1.474v12.832h8.468v1.35h-9.942z"></path>
        </g>
      </g>
    </chakra.svg>
  );
};
