import axios from 'axios';
import { getAccessToken } from '../utils/tokenHelper';

// const myMSALObj = new Msal.UserAgentApplication(msalConfig);

const API_URL =
  process.env.REACT_APP_ENV === 'dev'
    ? 'http://localhost:5000'
    : 'https://customer-portal-api.miworldwide.com';

axios.interceptors.request.use(async function (request) {
  request.headers['Content-Type'] = 'application/json';
  request.withCredentials = true;

  return request;
});

export const getCustomerOrders = async (
  page = 1,
  limit = 10,
  searchText = '',
  filters = [],
  dateRange = { startDate: '', endDate: '' },
  openOrders = false,
  shippingOrders = false
) => {
  const startDate = `startDate=${dateRange.startDate}`;
  const endDate = `endDate=${dateRange.endDate}`;
  const openOrdersQuery = `openOrders=${openOrders}`;
  const shippingOrdersQuery = `shippingOrders=${shippingOrders}`;
  return await axios.get(
    `${API_URL}/orders?page=${page}&limit=${limit}&searchText=${searchText}${
      filters.length && '&filters=' + filters
    }&${dateRange.startDate ? startDate : ''}&${
      dateRange.endDate ? endDate : ''
    }&${openOrdersQuery}&${shippingOrdersQuery}`
  );
};

export const getCustomerOrdersByIds = async (
  ids,
  shouldGetAllRecords,
  filters = [],
  dateRange = { startDate: '', endDate: '' },
  openOrders = false,
  shippingOrders = false
) => {
  const idsToPull = `ids=${ids}`;
  const getAllRecords = `shouldGetAllRecords=${shouldGetAllRecords}`;
  const filtersToPull = `filters=${filters}`;
  const startDate = `startDate=${dateRange.startDate ?? ''}`;
  const endDate = `endDate=${dateRange.endDate ?? ''}`;
  const openOrdersQuery = `openOrders=${openOrders}`;
  const shippingOrdersQuery = `shippingOrders=${shippingOrders}`;
  return await axios.get(
    `${API_URL}/orders?${idsToPull}&${getAllRecords}${
      filters.length ? '&' + filtersToPull : ''
    }&${startDate}&${endDate}&${openOrdersQuery}&${shippingOrdersQuery}`
  );
};

export const getCustomerOrdersInProgress = async () => {
  return await axios.get(`${API_URL}/orders/inprogress`);
};

export const getCustomerShipments = async (
  page = 1,
  limit = 10,
  searchText = '',
  filters = [],
  dateRange = { startDate: '', endDate: '' },
  openOrders = false,
  shippingOrders = false
) => {
  const startDate = `startDate=${dateRange.startDate}`;
  const endDate = `endDate=${dateRange.endDate}`;
  const openOrdersQuery = `openOrders=${openOrders}`;
  const shippingOrdersQuery = `shippingOrders=${shippingOrders}`;
  return await axios.get(
    `${API_URL}/shipments?page=${page}&limit=${limit}&searchText=${searchText}${
      filters.length ? '&filters=' + filters : ''
    }&${dateRange.startDate ? startDate : ''}&${
      dateRange.endDate ? endDate : ''
    }&${openOrdersQuery}&${shippingOrdersQuery}`
  );
};

export const getCustomerShipmentsXDaysAgo = async days => {
  return await axios.get(`${API_URL}/shipments/count?days=${days}`);
};

export const getCustomerShipmentsByIds = async (
  ids,
  shouldGetAllRecords,
  filters = [],
  dateRange = { startDate: '', endDate: '' },
  openOrders = false,
  shippingOrders = false
) => {
  const idsToPull = `ids=${ids}`;
  const getAllRecords = `shouldGetAllRecords=${shouldGetAllRecords}`;
  const filtersToPull = `filters=${filters}`;
  const startDate = `startDate=${dateRange.startDate ?? ''}`;
  const endDate = `endDate=${dateRange.endDate ?? ''}`;
  const openOrdersQuery = `openOrders=${openOrders}`;
  const shippingOrdersQuery = `shippingOrders=${shippingOrders}`;
  return await axios.get(
    `${API_URL}/shipments?${idsToPull}&${getAllRecords}${
      filters.length ? '&' + filtersToPull : ''
    }&${startDate}&${endDate}&${openOrdersQuery}&${shippingOrdersQuery}`
  );
};

export const getUsers = async () => {
  return await axios.get(`${API_URL}/users`);
};

export const createUser = async userInfo => {
  return await axios.post(`${API_URL}/users`, userInfo);
};

export const updateUser = async user => {
  return await axios.post(`${API_URL}/users/${user._id}`, user);
};

export const deleteUser = async id => {
  return await axios.delete(`${API_URL}/users/${id}`);
};

export const loginLocal = async userInfo => {
  return await axios.post(`${API_URL}/auth/login/local`, userInfo, {
    withCredentials: true,
  });
};

export const loginMicrosoft = async (msalInstance, loginRequest) => {
  const accessToken = await getAccessToken(msalInstance, loginRequest);
  return await axios.post(
    `${API_URL}/auth/login/aad`,
    {},
    {
      withCredentials: true,
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
};

export const signupLocal = async userInfo => {
  return await axios.post(`${API_URL}/auth/signup/local`, userInfo);
};

export const signOutUser = async () => {
  return await axios.get(`${API_URL}/auth/logout`);
};

export const getAnnouncements = async () => {
  return await axios.get(`${API_URL}/announcements`);
};

export const getUnreadAnnouncements = async () => {
  return await axios.get(`${API_URL}/announcements/count/unread`);
};

export const createAnnouncement = async announcement => {
  return await axios.post(`${API_URL}/announcements`, announcement);
};

export const getCompanies = async (page = 1, limit = 10, searchText = '') => {
  return await axios.get(
    `${API_URL}/companies?page=${page}&limit=${limit}&search=${searchText}`
  );
};

export const getUserId = async () => {
  return await axios.get(`${API_URL}/getUser`);
};

export const getUser = async () => {
  return await axios.get(`${API_URL}/auth`);
};
