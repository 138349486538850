import { Badge, Box, Flex, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { FiX } from 'react-icons/fi';

export default function ListOfBadges({ items = [], onClose }) {
  return (
    <Flex maxW={'full'} flexWrap={'wrap'}>
      {items.map((value, index) => (
        <Badge key={index} colorScheme={'purple'} m={1}>
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            fontSize={'sm'}
            p={1}
          >
            {value.displayName}
            <Tooltip label={'Remove company'}>
              <Box
                ml={1}
                _hover={{ cursor: 'pointer' }}
                onClick={() => onClose(value.id)}
              >
                <FiX fontSize="1rem" />
              </Box>
            </Tooltip>
          </Flex>
        </Badge>
      ))}
    </Flex>
  );
}
