// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: '4a82ad47-eda3-4b14-963a-07333414ce4c',
    authority:
      'https://login.microsoftonline.com/47718714-e0d2-4321-a78d-a93e36196341',
    redirectUri: '/_blank',
    postLogoutRedirectUri: '/',
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['api://c6799695-7355-4a9c-8dc7-9b0ba1c8864d/access_as_user'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
};
