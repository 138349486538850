import {
  Heading,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiInfo } from 'react-icons/fi';
import { getUsers } from './api/api';
import { USERS_TABLE_HEADERS } from './constants';
import { DataTable } from './DataTable';
import { Shell } from './Shell';
import { TableWithSearch } from './TableWithSearch';
import UserDeleteModal from './UserDeleteModal';
import UserEditModal from './UserEditModal';

export default function Users() {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [searchText, setSearchText] = useState('');
  const [tablePage, setTablePage] = useState(1);
  const [tableTotalPages, setTableTotalPages] = useState(1);
  const [tableTotalRecords, setTableTotalRecords] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenDeleteModal,
    onClose: onCloseDeleteModal,
    onOpen: onOpenDeleteModal,
  } = useDisclosure();

  useEffect(() => {
    setIsLoading(true);
    getUsers()
      .then(res => {
        setUsers(res.data.users);
        setTablePage(res.data.currentPage);
        setTableTotalPages(res.data.totalPages);
        setTableTotalRecords(res.data.totalRecords);
      })
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Shell>
      <Stack
        spacing={{
          base: '8',
          lg: '6',
        }}
      >
        <Stack
          spacing="4"
          direction={{
            base: 'column',
            lg: 'row',
          }}
          justify="space-between"
          align={{
            base: 'start',
            lg: 'center',
          }}
        >
          <Stack spacing="1">
            <Heading
              size={useBreakpointValue({
                base: 'xs',
                lg: 'sm',
              })}
              fontWeight="medium"
            >
              Users
            </Heading>
            <HStack>
              <FiInfo />
              <Text>
                <span
                  style={{
                    fontWeight: '700',
                    color: 'orange',
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                  }}
                >
                  Create a user
                </span>{' '}
                by inviting them in the "Companies" section.
              </Text>
            </HStack>
          </Stack>
          <HStack spacing="3">
            {/* <Button variant="primary" leftIcon={<FiEdit fontSize="1.25rem" />}>
              Create
            </Button> */}
          </HStack>
        </Stack>
        <TableWithSearch
          title="Users"
          onSearch={text => setSearchText(text)}
          page={tablePage}
          isLoading={isLoading}
          setPage={setTablePage}
          totalResults={tableTotalRecords}
          totalPages={tableTotalPages}
        >
          <DataTable
            isLoading={isLoading}
            headers={USERS_TABLE_HEADERS}
            data={users?.filter(
              value =>
                JSON.stringify(value)
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) || searchText === ''
            )}
            editable
            deleteable
            editClicked={user => {
              setUser(user);
              onOpen();
            }}
            deleteClicked={user => {
              setUser(user);
              onOpenDeleteModal();
            }}
          />
        </TableWithSearch>
        <UserEditModal isOpen={isOpen} onClose={onClose} user={user} />
        <UserDeleteModal
          isOpen={isOpenDeleteModal}
          onClose={onCloseDeleteModal}
          user={user}
        />
      </Stack>
    </Shell>
  );
}
