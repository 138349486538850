import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useState } from 'react';
import { createAnnouncement } from './api/api';

export default function CreateAnnouncementModal({
  isOpen,
  onClose,
  afterSave,
}) {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [announcement, setAnnouncement] = useState({
    title: '',
    content: '',
    createdAt: new Date(),
  });

  const onSubmit = async () => {
    setLoading(true);
    setTimeout(async () => {
      try {
        await createAnnouncement(announcement);
        afterSave(announcement);
        setSubmitted(true);
        setTimeout(() => {
          setSubmitted(false);
          onClose();
        }, 2000);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Announcement</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            {!submitted && (
              <>
                <FormControl>
                  <FormLabel htmlFor="Status">Title</FormLabel>
                  <Input
                    id="title"
                    type="text"
                    placeholder="Enter a title for your announcement"
                    onChange={e =>
                      setAnnouncement({
                        ...announcement,
                        title: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="Status">Content</FormLabel>
                  <Textarea
                    id="content"
                    type="text"
                    placeholder="Enter your announcement message"
                    onChange={e =>
                      setAnnouncement({
                        ...announcement,
                        content: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </>
            )}
            {submitted && !loading && (
              <>
                <Text>Posted your announcement successfully!</Text>
              </>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={'blue'}
            isLoading={loading}
            isDisabled={
              submitted ||
              loading ||
              !announcement.content ||
              !announcement.title
            }
            onClick={() => onSubmit()}
          >
            Post
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
