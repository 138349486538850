import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import * as React from 'react';
import { Navbar } from './Navbar';
import { Sidebar } from './Sidebar';

export const Shell = ({ children }) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  return (
    <Flex
      as="section"
      direction={{
        base: 'column',
        lg: 'row',
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? <Sidebar /> : <Navbar />}
      <Flex flexGrow={1} overflow={'scroll'}>
        <Box p={[2, 8]} w="full">
          {children}
        </Box>
      </Flex>
    </Flex>
  );
};
