import { useMsal } from '@azure/msal-react';
import { Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import { loginMicrosoft } from './api/api';
import { loginRequest } from './authConfig';
import { MicrosoftIcon } from './ProviderIcons';

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInWithMicrosoftButton = () => {
  const { instance } = useMsal();
  const [loading, setLoading] = useState(false);

  const login = async () => {
    loginMicrosoft(instance, loginRequest)
      .then(result => {
        console.log('success', result);
        window.location.pathname = '/';
      })
      .catch(err => {
        console.error('fail', err);
      });
  };

  const handleLogin = async instance => {
    setLoading(true);
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
    }
    try {
      const res = await instance.loginPopup(loginRequest);
      console.log(res.account);
      const account = res.account;
      await instance.setActiveAccount(account);
      await login();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      onClick={() => handleLogin(instance)}
      isLoading={loading}
      variant="secondary"
      leftIcon={<MicrosoftIcon boxSize="5" />}
      iconSpacing="3"
    >
      Sign in with Microsoft
    </Button>
  );
};
