import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import {
  FiBell,
  FiBriefcase,
  FiHome,
  FiPackage,
  FiShoppingCart,
  FiUsers,
} from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import { ROLES } from './constants';
import { useAuth } from './hooks/useProvideAuth';
import { Logo } from './Logo';
import { NavButton } from './NavButton';
import NavLightDarkToggle from './NavLightDarkToggle';
import { SignOutMenuItem } from './SignOutMenuItem';
import { UserProfile } from './UserProfile';

export const Sidebar = () => {
  const [user, setUser] = React.useState(null);
  const location = useLocation();
  const auth = useAuth();

  React.useEffect(() => {
    setUser(auth.user);
  }, [auth.user]);

  return (
    <Flex
      as="section"
      minH="100vh"
      bg="bg-canvas"
      minW={{ base: 'unset', lg: '18rem' }}
    >
      <Flex
        flex="1"
        bg="bg-surface"
        overflowY="auto"
        boxShadow={useColorModeValue('sm', 'sm-dark')}
        maxW={{
          base: 'full',
          sm: 'xs',
        }}
        py={{
          base: '6',
          sm: '8',
        }}
        px={{
          base: '4',
          sm: '6',
        }}
      >
        <Stack justify="space-between" spacing="1">
          <Stack
            spacing={{
              base: '5',
              sm: '6',
            }}
            shouldWrapChildren
          >
            <Logo />
            <Stack spacing="1">
              <NavButton
                label="Home"
                icon={FiHome}
                aria-current={location.pathname === '/' ? 'page' : ''}
                as={Link}
                to={'/'}
              />
              <NavButton
                label="Orders"
                icon={FiShoppingCart}
                aria-current={location.pathname === '/orders' ? 'page' : ''}
                as={Link}
                to={'/orders'}
              />
              <NavButton
                label="Shipments"
                icon={FiPackage}
                aria-current={location.pathname === '/shipments' ? 'page' : ''}
                as={Link}
                to={'/shipments'}
              />
              <NavButton
                label="Announcements"
                icon={FiBell}
                aria-current={
                  location.pathname === '/announcements' ? 'page' : ''
                }
                as={Link}
                to={'/announcements'}
              />
              {user && user.roles?.includes(ROLES.ADMIN) && (
                <>
                  <NavButton
                    label="Companies"
                    icon={FiBriefcase}
                    aria-current={
                      location.pathname === '/companies' ? 'page' : ''
                    }
                    as={Link}
                    to={'/companies'}
                  />
                  <NavButton
                    label="Users"
                    icon={FiUsers}
                    aria-current={location.pathname === '/users' ? 'page' : ''}
                    as={Link}
                    to={'/users'}
                  />
                </>
              )}
            </Stack>
          </Stack>
          <Stack
            spacing={{
              base: '5',
              sm: '6',
            }}
          >
            <Stack spacing="1">
              <NavLightDarkToggle />
            </Stack>
            <Divider />
            <Menu matchWidth>
              {({ isOpen }) => (
                <>
                  <MenuButton>
                    <UserProfile
                      name={user?.name}
                      image=""
                      email={user?.email}
                      rightIcon={
                        isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />
                      }
                    />
                  </MenuButton>
                  <MenuList>
                    <SignOutMenuItem />
                  </MenuList>
                </>
              )}
            </Menu>
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  );
};
