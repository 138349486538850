import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { deleteUser } from './api/api';

export default function UserDeleteModal({ isOpen, onClose, user }) {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await deleteUser(user._id);
      setSubmitted(true);
      setTimeout(() => {
        setSubmitted(false);
        onClose();
      }, 2000);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            {!submitted && (
              <>
                <Text>Are you sure you would like to delete {user.name}?</Text>
                <Text>This is irreversible and cannot be undone.</Text>
                <Text fontWeight={'bold'}>
                  You can make an account "inactive" by editing the account
                  instead.
                </Text>
              </>
            )}
            {submitted && !loading && (
              <>
                <Text fontWeight={'bold'}>Deleted user successfully!</Text>
              </>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={'red'}
            isLoading={loading}
            isDisabled={submitted}
            onClick={() => onSubmit()}
          >
            Delete Forever
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
