import { Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import { loginLocal } from './api/api';

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInEmailPasswordButton = ({ email, password }) => {
  const [loading, setLoading] = useState(false);

  const login = () => {
    setLoading(true);
    loginLocal({ username: email.trim(), password })
      .then(result => {
        window.location.pathname = '/';
      })
      .catch(err => {
        console.error('fail', err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Button onClick={() => login()} variant="primary" isLoading={loading}>
      Sign in
    </Button>
  );
};
