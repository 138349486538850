import { Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { loginLocal, signupLocal } from './api/api';
import { useAuth } from './hooks/useProvideAuth';
import { useQuery } from './hooks/useQuery';
/**
 * Renders a button which, when selected, will redirect the page to the signup prompt
 */
export const SignUpEmailPasswordButton = ({ name, email, password }) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const auth = useAuth();

  useEffect(() => {
    if (auth.user && window.location.pathname === '/signup') {
      window.location.pathname = '/';
    }
  }, [auth.user]);

  const signup = () => {
    const token = query.get('token');
    setLoading(true);

    signupLocal({ name: name.trim(), email: email.trim(), password, token })
      .then(async result => {
        console.log('successful sign up', result);
        const response = await loginLocal({ username: email.trim(), password });
        console.log('successful login', response);
        window.location.pathname = '/';
      })
      .catch(err => {
        console.error('fail', err);
        if (err.response?.data.message === 'Already signed up') {
          alert(
            'It looks like you already signed up.\n\nRedirecting you to the login page.'
          );
          window.location.pathname = '/auth';
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Button onClick={() => signup()} variant="primary" isLoading={loading}>
      Sign Up
    </Button>
  );
};
