import { useColorMode } from '@chakra-ui/react';
import React from 'react';
import { FiMoon, FiSun } from 'react-icons/fi';
import { NavButton } from './NavButton';

export default function NavLightDarkToggle() {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <NavButton
      label={'Toggle Mode'}
      icon={colorMode === 'light' ? FiSun : FiMoon}
      onClick={() => toggleColorMode()}
    />
  );
}
