import {
  Button,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiCheck, FiMail, FiPlus } from 'react-icons/fi';
import { getCompanies } from './api/api';
import { COMPANIES_TABLE_HEADERS } from './constants';
import { DataTable } from './DataTable';
import InviteCompanyModal from './InviteCompanyModal';
import ListOfBadges from './ListOfBadges';
import { Shell } from './Shell';
import { TableWithSearch } from './TableWithSearch';

export default function Companies() {
  const [searchText, setSearchText] = useState('');
  const [companies, setCompanies] = useState([]);
  const [companiesForUser, setCompaniesForUser] = useState([]);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [tablePage, setTablePage] = useState(1);
  const [tableTotalPages, setTableTotalPages] = useState(1);
  const [tableTotalRecords, setTableTotalRecords] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCompanies(tablePage, 10, searchText)
      .then(res => {
        setCompanies(res.data.companies);
        setTablePage(res.data.currentPage);
        setTableTotalPages(res.data.totalPages);
        setTableTotalRecords(res.data.totalRecords);
      })
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false));
  }, [tablePage, searchText]);

  return (
    <Shell>
      <InviteCompanyModal
        isOpen={isOpen}
        onClose={onClose}
        companies={companiesForUser}
      />
      <Stack
        spacing={{
          base: '8',
          lg: '6',
        }}
      >
        <Stack
          spacing="4"
          direction={{
            base: 'column',
            lg: 'row',
          }}
          justify="space-between"
          align={{
            base: 'start',
            lg: 'center',
          }}
        >
          <Stack spacing="1">
            <Heading
              size={useBreakpointValue({
                base: 'xs',
                lg: 'sm',
              })}
              fontWeight="medium"
            >
              Companies
            </Heading>
            <Text color="muted">Companies from past orders</Text>
          </Stack>
          <Tooltip
            label={
              companiesForUser.length
                ? 'Invite user with selected companies'
                : 'Add companies to invite user'
            }
            aria-label="Create user with selected companies"
          >
            <HStack>
              <Button
                colorScheme={'blue'}
                isDisabled={!companiesForUser.length}
                aria-label="Create user with selected companies button"
                leftIcon={<FiMail />}
                onClick={onOpen}
              >
                Invite User
              </Button>
            </HStack>
          </Tooltip>
        </Stack>
        <ListOfBadges
          items={companiesForUser}
          onClose={badgeId =>
            setCompaniesForUser(prev =>
              prev.filter(company => company.id !== badgeId)
            )
          }
        />
        <TableWithSearch
          title="Companies"
          onSearch={text => setSearchText(text)}
          page={tablePage}
          isLoading={isLoading}
          setPage={setTablePage}
          totalResults={tableTotalRecords}
          totalPages={tableTotalPages}
        >
          <DataTable
            isLoading={isLoading}
            customButton={id => (
              <Tooltip label="Add company to invitation">
                <Icon
                  id={id}
                  isDisabled={
                    companiesForUser.filter(company => company.id === id).length
                  }
                  onClick={e => {
                    const selected = companies.find(
                      company => company._id === id
                    );

                    setCompaniesForUser(prev => {
                      const companyExists = prev.find(
                        company => company.id === id
                      );

                      if (companyExists) {
                        return prev.filter(value => value.id !== id);
                      }

                      return [
                        ...prev,
                        { id, displayName: selected.customerCompany },
                      ];
                    });
                  }}
                  _hover={{
                    color: 'gray.400',
                    cursor: 'pointer',
                  }}
                  as={
                    companiesForUser.filter(company => company.id === id).length
                      ? FiCheck
                      : FiPlus
                  }
                  variant="ghost"
                  aria-label="Send Invitation"
                />
              </Tooltip>
            )}
            headers={COMPANIES_TABLE_HEADERS}
            data={companies?.filter(
              value =>
                JSON.stringify(value)
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) || searchText === ''
            )}
          />
        </TableWithSearch>
      </Stack>
    </Shell>
  );
}
