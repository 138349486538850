import { Link, MenuItem } from '@chakra-ui/react';
import React from 'react';
import { useAuth } from './hooks/useProvideAuth';

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutMenuItem = () => {
  const auth = useAuth();

  const signOut = async () => {
    auth.signOut();
  };

  return (
    <MenuItem as={Link} onClick={() => signOut()}>
      Sign out
    </MenuItem>
  );
};
