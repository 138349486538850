import { FormControl, FormLabel, HStack, Input } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

export default function DateRangePicker({
  value = {
    startDate: '',
    endDate: '',
  },
  onChange = () => {},
  ...rest
}) {
  const [startDate, setStartDate] = useState(value.startDate);
  const [endDate, setEndDate] = useState(value.endDate);

  useEffect(() => {
    onChange({
      startDate,
      endDate,
    });
  }, [startDate, endDate]);

  const handleStartDateChange = e => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = e => {
    setEndDate(e.target.value);
  };
  return (
    <HStack spacing={1} mx={4} {...rest}>
      <FormControl id="startDate">
        <FormLabel>Start Date</FormLabel>
        <Input
          zIndex={'popover'}
          type={'date'}
          value={startDate}
          onChange={handleStartDateChange}
        />
      </FormControl>
      <FormControl id="endDate">
        <FormLabel>End Date</FormLabel>
        <Input
          zIndex={'popover'}
          type={'date'}
          value={endDate}
          onChange={handleEndDateChange}
        />
      </FormControl>
    </HStack>
  );
}
