import {
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { useAuth } from './hooks/useProvideAuth';
import { Logo } from './Logo';
import { SignInEmailPasswordButton } from './SignInEmailPasswordButton';
import { SignInWithMicrosoftButton } from './SignInWithMicrosoftButton';

export const Auth = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const auth = useAuth();

  React.useEffect(() => {
    if (auth.user && window.location.pathname === '/auth') {
      window.location.pathname = '/';
    }
  }, [auth.user]);

  return (
    <Flex align={'center'} h={'100vh'}>
      <Container
        maxW="md"
        py={{
          base: '12',
          md: '24',
        }}
        mb={{
          base: '12',
          md: '24',
        }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            <Logo />
            <Stack
              spacing={{
                base: '2',
                md: '3',
              }}
              textAlign="center"
            >
              <Heading
                size={useBreakpointValue({
                  base: 'xs',
                  md: 'sm',
                })}
              >
                Log in to your account
              </Heading>
            </Stack>
          </Stack>
          <Stack spacing="6">
            <Stack spacing="-px">
              <FormControl id="email">
                <FormLabel srOnly>Email address</FormLabel>
                <Input
                  name="email"
                  type="email"
                  placeholder="Email"
                  roundedBottom="0"
                  onChange={e => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel srOnly>Password</FormLabel>
                <Input
                  name="password"
                  type="password"
                  placeholder="Password"
                  roundedTop="0"
                  onChange={e => setPassword(e.target.value)}
                />
              </FormControl>
            </Stack>
            <HStack justify="space-between">
              <Checkbox defaultIsChecked>Remember me</Checkbox>
              <Button variant="link" colorScheme="blue" size="sm">
                Forgot password
              </Button>
            </HStack>
            <Stack spacing="4">
              <SignInEmailPasswordButton email={email} password={password} />
              <SignInWithMicrosoftButton />
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};
