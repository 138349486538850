export const SHIPMENTS_TABLE_HEADERS = {
  packingSlipNumber: { label: 'Packing Slip #', type: 'text' },
  customerPONumber: { label: 'PO #', type: 'text' },
  backorderQuantity: { label: 'Left To Ship', type: 'text' },
  quantityShipped: { label: 'Quantity Shipped', type: 'text' },
  inventoryItemRecordId: { label: 'Item', type: 'text' },
  itemDescription: { label: 'Item Description', type: 'text' },
  shipDate: { label: 'Ship Date', type: 'date' },
  trackingNumber: { label: 'Tracking #', type: 'text' },
  shippedVia: { label: 'Ship Via', type: 'text' },
  customerCompany: { label: 'Ship To', type: 'text' },
  billToAttention: { label: 'Bill To', type: 'text' },
};

export const ORDERS_TABLE_HEADERS = {
  customerCompany: { label: 'Company', type: 'text' },
  salesOrderNumber: { label: 'Sales Order #', type: 'text' },
  customerPONumber: { label: 'PO #', type: 'text' },
  billToPrimaryContact: { label: 'Sales Order Contact', type: 'text' },
  itemNumber: { label: 'Item #', type: 'text' },
  itemDescription: { label: 'Item Description', type: 'text' },
  quantityShipped: { label: 'Quantity Shipped', type: 'text' },
  backorderQuantity: { label: 'Left To Ship', type: 'text' },
  shipToAddressLineOne: { label: 'Ship To', type: 'text' },
  orderDate: { label: 'Order Date', type: 'date' },
  promisedDate: { label: 'Promised Date', type: 'date' },
  shipDate: { label: 'Ship Date', type: 'date' },
};

export const COMPANIES_TABLE_HEADERS = {
  customerCompany: { label: 'Company Name', type: 'text' },
  customerNumber: { label: 'Company ID', type: 'text' },
  primaryContact: { label: 'Primary Contact', type: 'text' },
  customerRecordId: { label: 'Record ID', type: 'text' },
  customerAddressLineOne: { label: 'Address Line', type: 'text' },
  customerAddressLineTwo: { label: 'Address Line #2', type: 'text' },
  customerCity: { label: 'City', type: 'text' },
  customerState: { label: 'State', type: 'text' },
  customerZip: { label: 'Zip', type: 'text' },
};

export const USER_STATUS_DETAILS = {
  ACTIVE: { name: 'Active', color: 'green' },
  INACTIVE: { name: 'Inactive', color: 'red' },
  PENDING_INVITATION: { name: 'Pending Invitation', color: 'yellow' },
};

export const USER_STATUS_MAP = {
  Active: USER_STATUS_DETAILS.ACTIVE,
  Inactive: USER_STATUS_DETAILS.INACTIVE,
  'Pending Invitation': USER_STATUS_DETAILS.PENDING_INVITATION,
};

export const USERS_TABLE_HEADERS = {
  name: { label: 'Name', type: 'text' },
  status: { label: 'Status', type: 'enum', options: USER_STATUS_MAP },
  roles: { label: 'Roles', type: 'array' },
  email: { label: 'Email', type: 'text' },
  companyIds: { label: 'Company Numbers', type: 'array' },
};

export const ROLES = {
  ADMIN: 'Admin',
  CUSTOMER: 'Customer',
  MONOFLO_EMPLOYEE: 'Monoflo Employee',
};

export const USER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  PENDING_INVITATION: 'Pending Invitation',
};
