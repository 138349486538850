import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FiCopy } from 'react-icons/fi';
import { createUser } from './api/api';

export default function InviteCompanyModal({ isOpen, onClose, companies }) {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [currentInviteUrl, setCurrentInviteUrl] = useState('');
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const companyIds = companies.map(company => company.id);
      const response = await createUser({ companyIds });
      const inviteUrl = response.data.inviteUrl;
      setSubmitted(true);
      setCurrentInviteUrl(inviteUrl);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(currentInviteUrl);
    setCopiedToClipboard(true);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 2000);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setLoading(false);
        setCurrentInviteUrl('');
        setCopiedToClipboard(false);
        setSubmitted(false);
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite Company</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            {!submitted && (
              <>
                <Text>
                  You can generate a link to invite a company to join the
                  platform.
                </Text>
                <Text>
                  This link will be active for 7 days. After that, it will
                  expire.
                </Text>
              </>
            )}
            {submitted && !loading && (
              <>
                <Text fontWeight={'semibold'}>
                  Email this link to your customer:
                </Text>
                <Text isTruncated>{currentInviteUrl}</Text>
              </>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          {!currentInviteUrl && (
            <Button
              colorScheme={'blue'}
              isLoading={loading}
              onClick={() => onSubmit()}
            >
              Generate Link
            </Button>
          )}
          {currentInviteUrl && (
            <Button
              colorScheme={'blue'}
              leftIcon={<FiCopy fontSize="1.25rem" />}
              isDisabled={copiedToClipboard}
              onClick={() => copyToClipboard()}
            >
              {copiedToClipboard ? 'Copied!' : 'Copy Link'}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
