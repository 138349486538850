import { InteractionRequiredAuthError } from '@azure/msal-browser';

export const getAccessToken = async (msalInstance, loginRequest) => {
  try {
    const accessTokenResponse = await msalInstance.acquireTokenSilent(
      loginRequest
    );
    return accessTokenResponse.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      try {
        const accessTokenPopupResponse = await msalInstance.acquireTokenPopup(
          loginRequest
        );

        return accessTokenPopupResponse.accessToken;
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log(error);
    }
  }
};
