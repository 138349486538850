import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { updateUser } from './api/api';
import { ROLES, USER_STATUS } from './constants';

export default function UserEditModal({ isOpen, onClose, user }) {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [updatedUser, setUpdatedUser] = useState({});

  useEffect(() => {
    setUpdatedUser({ roles: user.roles, status: user.status });
  }, [user]);

  const onSubmit = async () => {
    setLoading(true);
    setTimeout(async () => {
      try {
        await updateUser(updatedUser);
        setSubmitted(true);
        setTimeout(() => {
          setSubmitted(false);
          onClose();
        }, 2000);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            {!submitted && (
              <>
                <FormControl>
                  <FormLabel htmlFor="Status">Status</FormLabel>
                  <Select
                    id="Status"
                    placeholder="Select status"
                    value={user.status}
                    onChange={e => {
                      setUpdatedUser({
                        ...updatedUser,
                        status: e.target.value,
                      });
                    }}
                  >
                    {Object.keys(USER_STATUS).map(status => (
                      <option key={status} value={USER_STATUS[status]}>
                        {USER_STATUS[status]}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="Status">Roles</FormLabel>
                  <CheckboxGroup colorScheme="blue" defaultValue={user.roles}>
                    <Stack spacing={[1, 5]} direction={['column', 'row']}>
                      {Object.keys(ROLES).map(role => (
                        <Checkbox
                          key={role}
                          value={ROLES[role]}
                          onChange={e => {
                            const newRoles = [...updatedUser.roles];
                            if (e.target.checked) {
                              newRoles.push(ROLES[role]);
                            } else {
                              newRoles.splice(newRoles.indexOf(ROLES[role]), 1);
                            }
                            setUpdatedUser({ ...updatedUser, roles: newRoles });
                          }}
                        >
                          {ROLES[role]}
                        </Checkbox>
                      ))}
                    </Stack>
                  </CheckboxGroup>
                </FormControl>
              </>
            )}
            {submitted && !loading && (
              <>
                <Text>Updated successfully!</Text>
              </>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={'blue'}
            isLoading={loading}
            isDisabled={submitted || loading}
            onClick={() => onSubmit()}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
