import { Avatar, Box, HStack, Text } from '@chakra-ui/react';
import * as React from 'react';

export const UserProfile = ({ name, image, email, rightIcon, ...rest }) => {
  return (
    <HStack spacing="3" ps="2" {...rest}>
      <Avatar name={name} src={image} boxSize="10" />
      <Box maxW={40} textAlign="start" w={'full'}>
        <Text fontWeight="medium" fontSize="sm" isTruncated>
          {name}
        </Text>
        <Text color="muted" fontSize="sm" isTruncated>
          {email}
        </Text>
      </Box>
      {rightIcon}
    </HStack>
  );
};
