import {
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { Logo } from './Logo';
import { SignUpEmailPasswordButton } from './SignUpEmailPasswordButton';

export const Signup = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [name, setName] = React.useState('');

  return (
    <Flex align={'center'} h={'100vh'}>
      <Container
        maxW="md"
        py={{
          base: '12',
          md: '24',
        }}
        mb={{
          base: '12',
          md: '24',
        }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            <Logo />
            <Stack
              spacing={{
                base: '2',
                md: '3',
              }}
              textAlign="center"
            >
              <Heading
                size={useBreakpointValue({
                  base: 'xs',
                  md: 'sm',
                })}
              >
                Sign up for your account
              </Heading>
            </Stack>
          </Stack>
          <Stack spacing="6">
            <Stack spacing="-px">
              <FormControl id="name">
                <FormLabel srOnly>Name</FormLabel>
                <Input
                  name="name"
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder="Name"
                  roundedBottom="0"
                />
              </FormControl>
              <FormControl id="email">
                <FormLabel srOnly>Email address</FormLabel>
                <Input
                  name="email"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Email"
                  roundedTop="0"
                  roundedBottom="0"
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel srOnly>Password</FormLabel>
                <Input
                  name="password"
                  type="password"
                  placeholder="Password"
                  roundedTop="0"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </FormControl>
            </Stack>
            <Stack spacing="4">
              <SignUpEmailPasswordButton
                name={name}
                email={email}
                password={password}
              />
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};
