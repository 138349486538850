import { Box, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';

export const Card = ({ children, ...rest }) => (
  <Box
    minH="3xs"
    bg="bg-surface"
    boxShadow={useColorModeValue('sm', 'sm-dark')}
    borderRadius="lg"
    {...rest}
  >
    {children}
  </Box>
);
